<template>
  <div class="main" :class="show ? 'h5' : ''">
        <mtop />

    <div class="yq">
      <img src="../assets/img/yaoqing.png" alt="" @click="toPage('/Nftlist')" srcset="" class="yqico" v-if="dqyy=='zh-CN'">
        <img src="../assets/img/yaoqingen.png" alt="" @click="toPage('/Nftlist')" srcset="" class="yqico" v-else>
    </div>
    <div class="box">
       <div class="haerbox">
          <div class="nftimg">
        <div class="nft"></div>
        </div>
        <!-- <div class="name">{{$t('m.v1nft')}}</div>  -->
        </div>
        <div class="mynum">
          <div class="item">
            <div class="num">{{zzNum}} {{$t('m.mei')}}</div>
            <div class="txt">{{$t('m.yizhuzao')}}</div>
          </div>
            <div class="item">
            <div class="num">{{mynum}} {{$t('m.mei')}}</div>
            <div class="txt">{{$t('m.weizhuzao')}}</div>
          </div>
           <div class="item">
            <div class="num">{{ljtoast}} GBB
              <img src="../assets/img/icon-jiantou.png" alt="" @click="toTpshow" class="jiesou">
            </div>
            <div class="txt">{{$t('m.leijishifang')}}</div>
          </div>
        </div>  
        <div class="flex-btn">
          <van-button
          :loading="loading"
          :disabled="mynum<=0"
          @click="toshow"
          type="primary"
          class="btn"
          loading-text="Loading..."
        >
         {{$t('m.zhuzao')}} GBB</van-button
        >
   
        </div>     
       
      </div>
         <div class="rule">
          <div class="card">               
          <div class="hcbg"> BBGen{{$t('m.zhuzaoguize')}}</div>
          <img src="../assets/img/zzgzs.png" alt="" class="gzbg" v-if="dqyy=='zh-CN'">
          <img src="../assets/img/zzgzsx.png" alt="" class="gzbg" v-else>
          <div class="bz">
            <div class="le">{{$t('m.zhu')}}</div>
            <div class="rl">
            <div class="p">{{$t('m.zhuzaoguize1')}}</div>
            <div class="p"> {{$t('m.zhuzaoguize2')}}</div>
            <div class="p"> {{$t('m.zhuzaoguize3')}}</div>
            <div class="p"> {{$t('m.zhuzaoguize4')}}</div>
            </div>
          </div>
        </div>
          </div>
    <div class="content">
      <div class="stopm">
      <div class="stitle" v-if="active==0">{{$t('m.wodezhuzao')}}</div>
      <div class="lqr" @click="toPage('/Record')">{{$t('m.lingqujilu')}}<img src="../assets/img/lqjl.png" alt=""></div>
    </div>
    

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh"  
        :loosing-text="$t('m.loading')"
        :loading-text="$t('m.loading')">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
            :finished-text="$t('m.nomore')"
          style="min-height: 100vh"
          
        >
          <div
            v-for="(item, i) in list"
            :key="i"
            class=""
          >
          <div class="item" v-if="active==0">
            <div class="boxtop">
            <div class="left">
            <img :src="nftImg" alt="" srcset="" class="nft">
            <div class="name">
              <div class="title">{{ $t('m.v1nft')}}</div>             
              </div>
            </div>
            <div class="right">
              x{{ item.amount }}
            </div>
            </div>
            <div class="boxbot">
              <div class="lines">
                <div class="left">{{$t('m.zhuzaoshijian')}}</div>
                <div class="right">{{item.startTime}}</div>
              </div>
                <div class="lines">
                <div class="left">{{$t('m.zhuzaozhouqi')}}</div>
                <div class="right">{{item.Day}}/{{item.stakeDays}} {{$t('m.day')}}</div>
              </div>
                <div class="lines">
                <div class="left">{{$t('m.shifangliang')}}</div>
                <div class="right">{{item.DayNum}}/{{50*item.amount*item.stakeDays}} GBB 
                  <!-- <img src="../assets/img/rl.png" alt="" class="ic" srcset=""> -->
                </div>
              </div>
              <div class="swpl">
                <div class="act" :style="{'width':((item.DayNum)/(item.stakeDays*50*item.amount)*100)+'%'}"></div>
              </div>
            </div>
       <div class="zzbox">
              <div class="sit">
                <div class="num">{{item.claimAmount}} GBB</div>
                <div class="txt">{{$t('m.yilingqu')}}</div>
              </div>
              <div class="sit">
                <div class="num">{{(item.DayNum-item.claimAmount>0?item.DayNum-item.claimAmount:0)}} GBB</div>
                <!-- <div class="num">{{(item.DayNum)}} NT</div> -->
                <div class="txt">{{$t('m.dailingqu')}}</div>
              </div>
              <div class="sit">
                
                   <van-button
          :loading="loading||uniLoading"
          :disabled="Number(item.DayNum)<=0"
          type="primary"
          @click="toinvest(item)"
          class="btn"
          loading-text="Loading..."
        >
         {{$t('m.liqu')}}</van-button
        >
                 <!-- <div class="btn" >{{$t('m.liqu')}}</div> -->
              </div>
            </div>
            </div>
          </div>
          <div v-if="list.length == 0" class="noData">
            <!-- <div class="ntxt">{{$t('m.zwsj')}}</div> -->
            <img src="../assets/logo.png" alt="" srcset="">
          </div>
          <div class="kongbai"></div>
        </van-list>
      </van-pull-refresh>

    </div>
    <binding
      :show="tshow"
      :data="plandata"
      @tshow="
        (val) => {
          tshow = val;
        }"
      @updata="
        (val) => {
          tshow = false;
         this.getmyNum()
        }
      "
    />

   
      <Tips
      :show="tpshow"
       :data="plandata"
        @tshow="
        (val) => {
          tpshow = val;
        }"
      />
     <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import Tranfer from "../components/transfer.vue"
import binding from '../components/binding.vue'
import Tips from '../components/tips.vue'
import {walletOfOwner,ImgV1,getChildren,getZynum,getstakerTokens,getstakerDay,invest,sendTrans,getNoce} from "@/api/Public";
export default {
  data() {
    return {
      email: "",
      show: false,
      uniLoading:false,
      active: 0,
        list: [],
        page: 0,
        finished: false,
        loading: false,
        tshow: false,
        plandata: {},
        refreshing: false,
      mynum: 0,
        zzNum:0,
        nftImg: ImgV1,
      cur: 0,
      bdshow: false,
      tpshow: false,
      ljtoast:0,
      uniNum:''
    };
    },
    components: {
    mtop,
        mbottom,
      Tranfer,
      binding,
    Tips
  },
    created() {
            let i = setInterval(() => {
      if ((typeof window.ethereum) !== 'undefined') {   
      this.getmyNum();
        clearInterval(i)
    }
}, 1000); 
    },
    filters: {
        yincang(val) {
            if (val) {
        return val.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
         },
  },
  mounted() {
    window.getNt=(info)=>{
      this.handleTrans(info)
    }
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
      },
     "$store.getters.userWallet": function () {
      this.getmyNum();
    },
  },

    computed: {
      dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "zh-CN";
      }
    },
  },
  methods: {
    toNtSend(){
      this.$router.push({ path: 'transNT' });
    },
    async handleTrans(info){
      this.uniLoading=true
      const res= await sendTrans(info)
      if(res.success){
        this.uprecode(this.uniNum)
        this.getmyNum()
            this.$notify({ message: this.$i18n.t("m.lingquchenggong"), type: "success" });            
            this.onLoad()
      }else{
        this.$notify(this.$i18n.t("m.lingqushibai"))
      }
      this.uniLoading=false
      },
    toTpshow() { 
      if(this.tpshow){this.tpshow=false}
      setTimeout(() => {
        this.tpshow=true     
      }, 100);
       
    },
          onCopy() {
      this.$toast({ message: this.$i18n.t("m.fzcg"), icon:'none'});
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
        getmyNum() { 
            walletOfOwner(this.$store.getters.userWallet,0).then(res => {
                this.mynum = res
                this.onLoad()
                // this.list[0].length=this.mynum
            })
          getZynum(this.$store.getters.userWallet).then(res => {
              this.zzNum=res
            })
        },
        getNum(address, i) { 
            walletOfOwner(address,i).then(res => {
              console.log(res)
                this.$set(this.list[this.active][i],'address',address)
                this.$set(this.list[this.active][i], 'num', res)
                this.$forceUpdate()
                 this.loading = false;
            })
        },
        getList() { 

    },
   async toinvest(e) { 
    this.uniNum=e.DayNum
      sessionStorage.setItem('type',7)
      let resp= await getNoce(this.$store.getters.userWallet)
     if(resp.success){
      uni.postMessage({
        data: {
          noce: resp.res
        
        }
      })
     }
      if (e.DayNum >= 0) {       
        this.loading=true
        invest(this.$store.getters.userWallet,e.id).then(res => {
          this.loading=false
          if (res) {
            this.uprecode(e.DayNum)
            this.$notify({ message: this.$i18n.t("m.lingquchenggong"), type: "success" });            
            this.onLoad()
          } else {
            // this.$notify(this.$i18n.t("m.lingqushibai"))
          }
        })
      }
    },
    uprecode(e) { 
      let timeStamp = new Date().getTime()
     let time=  new Date(timeStamp).toLocaleString();
      let list = JSON.parse(localStorage.getItem(this.$store.getters.userWallet))||[]
      let obj = {num:e,time:time}
      list.unshift(obj)
      localStorage.setItem(this.$store.getters.userWallet,JSON.stringify(list))
    },
        toshow() { 
            if (this.mynum > 0) {
                if (this.tshow) { this.tshow = false }
              setTimeout(() => {
                    this.plandata={maxnum:this.mynum}
                    this.tshow = true
                }, 100);
            } else {
                this.getmyNum()
            }
        },
        onLoad() { 
               this.loading = false;
          this.finished = true;    
          getstakerTokens(this.$store.getters.userWallet, this.page).then(res => {
            this.list = res.map((el, i) => {
              let esl = Object.assign({}, el)
              esl.startTime =new Date(el.startTime*1000).toLocaleString();
              esl.DayNum = 0
              esl.claimAmount=Number((el.claimAmount/10**18).toFixed(0))
              getstakerDay(this.$store.getters.userWallet, el.id).then(num => {   
                let day = (num)*50*el.amount
                this.$set(this.list[i], 'Day', num)
                this.$set(this.list[i], 'DayNum', day)    
                this.$set(this.list[i], 'ljtoast', day )  
                // console.log(this.ljtoast); 
                this.jsmap()
              })            
              return esl             
            })             
            // console.log(this.list);
               this.refreshing=false
            })
        
    },
    jsmap() { 
       this.ljtoast=0
      this.list.map(el => {
        this.ljtoast=this.ljtoast+el.ljtoast
      })
    },
         onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
        },
        togetChaildren() { 
            this.list[2]=[]
            getChildren(this.$store.getters.userWallet, this.type).then(res => {  
                  if (res.length > 0) {                   
                      res.map((el, i) => {
                         this.list[2].push({address:'',num:''})
                          this.getNum(el, i)
                      })
                    }
                })
        },
        tochanger(e) {
            this.active = e
            if (e == 2) {
                 this.loading = true;
                 setTimeout(() => {
                    this.togetChaildren()          
                 }, 100);
                   
            }
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    open(e) {
      window.open(e);
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    to(url) {
      window.open(url);
    },
    toPage(e) {
      if (e == this.$route.path) {
        return;
      }
      this.$router.push({ path: e });
    },
    ding() {
      if (this.email) {
        const reg = /^([a-zA-Z0-9]+[-_.]?)+@[a-zA-Z0-9]+.[a-z]+$/;
        if (reg.test(this.email)) {
          this.$toast({
            message: "Subscribe to the success",
            type: "success",
          });
          this.email = "";
        }
      } else {
        this.$notify("Please enter your email number");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
.main {  
   background: #ffffff;
// padding-top: 200px;
   .bg{
    background: #F7F7F7;
   }
 .haerbox{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top:100px;
      .nftimg{
        position: relative;
        .nft{
            width: 159px;
height: 159px;
border:none;
           border-radius: 100px;
        }
      }
    
      .name{
        font-size: 32px;
font-weight: 500;
color: #ffffff;
line-height: 44px;
margin: 12px 0;
      }
   
    }
   .box{
    width: 750px;
min-height: 392px;
  background: url("../assets/img/bg.png") no-repeat top center;
    background-size: cover;
box-sizing: border-box;
padding: 32px;
.tab{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .item{
    width: 142px;
height: 54px;
background: transparent;
border-radius: 8px;
text-align: center;
line-height: 54px;
font-size: 24px;
font-weight: 400;
color: #ffffff;
  }
  .act{
    color: #009270;
    background: #DCEBE8;
  }
}
 .mynum{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 8px 16px 0px;
        width: calc(100% - 32px);
        .item{
          .num{
            font-size: 32px;
font-weight: 500;
color: #ffffff;
line-height: 44px;
.jiesou{
  width: 28px;
  height: 28px;
}
          }
          .txt{
            font-size: 28px;
font-weight: 400;
color: #ffffff;
line-height: 40px;
          }
        }
      }
      .flex-btn{
          display: flex;
    
              .btn{
      width: 306px;
height: 80px;
background: #FFFFFF;
color: #009270;
border-radius: 4px;
font-size: 32px;
font-weight: 500;
display: flex;
align-items: center;
justify-content: center;
margin: 40px auto 0;
    }
    .trans-btn{
      width: 306px;
height: 80px;
background: #FFFFFF;
color: #009270;
border-radius: 4px;
font-size: 32px;
font-weight: 500;
display: flex;
align-items: center;
justify-content: center;
margin: 40px auto 0;
}
}

   }
   .yq{
    position: fixed;
    right: 0;
    bottom: 100px;
    z-index: 999;
    .yqico{
      width:196px;
      height: 196px;
      cursor: pointer;
    }
   }
.content{
    // height: calc(100vh - 200px);
    // overflow-y: auto;
    .stitle{
      text-align: left;
      font-size: 24px;
font-weight: 600;
color: #181818;
line-height: 34px;

    }
    .stopm{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin:32px 32px 0;
      .lqr{
        font-size: 24px;
font-weight: 400;
color: #009270;
line-height: 34px;
display: flex;
align-items: center;
        img{
          width: 26px;
          height: 26px;
        }
      }
    }
.item{
  padding: 32px;
            background: #F7F7F7;
            margin: 32px;
            border-radius: 16px;
}
    .boxtop{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;          
            
        .left{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .nft{
                width: 100px ;
                height: 100px;
                margin-right:20px;
                border-radius: 8px;
            }
            .title{
                font-size: 32px;
font-weight: 500;
color: #333333;
line-height: 44px;
text-align: left;
            }
            .time{
                font-size: 28px;
                text-align: left;
font-weight: 400;
color: #999999;
line-height: 40px;
            }
        }
        .right{
            font-size: 28px;
font-weight: 500;
color: #999999;
line-height: 40px;
letter-spacing: 2px;
        }

        .sleft{
            display: flex;
            flex-direction: column;
            text-align: left;
            .title{
                font-size: 28px;
font-weight: 400;
color: #181818;
line-height: 40px;
            }
            .address{
                display: flex;
                align-items: center;
                font-size: 28px;
                .ic{
                    width:22px ;
                    height: 22px;
                    margin: 0 10px;
                }
            }
        }
          .sright{
            display: flex;
            flex-direction: column;
            text-align: right;
        font-size: 28px;
font-weight: 400;
color: #181818;
.snum{
    font-weight: 500;
}
.time{
    font-size: 28px;
}
        }
    }
    .boxbot{
      .lines{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 0;
        .left{
          font-size: 28px;
font-weight: 400;
color: #999999;
line-height: 40px;
        }
        .right{
          font-size: 28px;
font-weight: 400;
color: #333333;
line-height: 40px;
display: flex;
align-items: center;
.ic{
  width: 22px;
  height: 22px;
}
        }
      }
      .swpl{
        background: #E7E7E7;
border-radius: 8px;
width: 100%;
height: 12px;
position: relative;
.act{
  position: absolute;
  left: 0;
  height: 12px;
  border-radius: 8px;
  background: #009270;
}
      }
    }
}

.btn{
       min-width: 152px;
height: 64px;
background: transparent;
border-radius: 4px;
border: 1px solid #1B9070;  
font-size: 32px;
font-weight: 500;
color: #1B9070;
display: flex;
align-items: center;
justify-content: center;
margin: 0px auto;
}

.zzbox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0px 0;
  .sit{
    .num{
      font-size: 32px;
font-weight: 500;
color: #181818;
line-height: 44px;
    }
    .txt{
      font-size: 24px;
font-weight: 400;
color: #999999;
line-height: 34px;
    }
  }
}
.rule{
  text-align: left;
  margin: 32px 29px;

  .hcbg{
        background: url('../assets/img/hcgzbg.png') no-repeat;
        background-size: 100% 100%;
        height: 84px;
        width: 100%;
        font-size: 28px;
font-weight: 600;
color: #009270;
line-height: 34px;
display: flex;
align-items: center;
padding-left: 60px;
box-sizing: border-box;
      }
      .gzbg{
        width: 100%;
        height: 100%;
        margin-top: 22px;
      }
      .bz{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 28px 22px;
        .le{
          font-size: 16px;
font-weight: 400;
color: #868686;
line-height: 22px;
white-space: nowrap;
margin-right: 22px;
        }

      }
  .title{
    font-size: 32px;
font-weight: 500;
color: #232428;
line-height: 44px;
margin-bottom: 14px;
  }
  .p{
    font-size: 24px;
font-weight: 400;
color: #7A7D85;
margin-bottom: 22px;
  }
}
 .fuzhi {
        background: transparent;
        border: none;
      }
       .noData {
      min-height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .ntxt {
        line-height: 44px;
        font-size: 26px;
        color: #232323;
        font-weight: 600;
      }
      img{
        opacity: 0.5;
        width:50%;
        height:100%;

      }
      .image {
        width: 486px;
        margin: 30px auto;
        opacity: 0.5;
      }
    }
}
</style>